import '../assets/css/Footer.css';
import logo from '../assets/img/logo_white.png';
import { BsArrowRight } from 'react-icons/bs';

export const Footer = () => {
    return (
        <div className="container">
            <footer className="footer">
                <img src={logo} alt="Fredsgatan 12 logo" className="footer__logo" height="40"/>
                <div className="footer__content">
                    <div className="left__footer">
                        <div className="info__box">
                            <div className="info__box__text">
                                <h3>Öppettider</h3>
                                <div className="right__info">
                                    <span>Alla dagar</span>
                                    <span>18 - 05</span>
                                </div>
                            </div>
                            <div className="info__box__text">
                                <h3>Kontakt</h3>
                                <div className="right__info">
                                    <a href="mailto:info@fredsgatantolv.se">info@fredsgatantolv.se</a>
                                </div>
                            </div>
                            <div className="info__box__text">
                                <h3>Adress</h3>
                                <div className="right__info">
                                    <span>Fredsgatan 12</span>
                                    <span>111 52, Stockholm</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="right__footer__wrap">
                        <div className="right__footer">
                            <div className="french__group">
                                <h3>
                                    By French Group
                                </h3>
                                <p>
                                    French Group har byggt upp en exceptionell portfölj av de mest ikoniska platserna för privata och företagsevenemang där Fredsgatan 12 är så mycket mer än bara en adress. Det är en plats som berättar en historia, förtrollar dig i dess atmosfär och blåser liv i varje tillfälle.
                                </p>
                            </div>
                            <div className="footer__links">
                                <a href="https://www.instagram.com/fredsgatantolv/" target="_blank" rel="noreferrer">
                                    Instagram&nbsp;
                                    <BsArrowRight />
                                </a>
                                <a href="https://laboca.se" target="_blank" rel="noreferrer">
                                    Laboca&nbsp;
                                    <BsArrowRight />
                                </a>
                                <a href="https://frenchgroup.se" target="_blank" rel="noreferrer">
                                    French Group&nbsp;
                                    <BsArrowRight />
                                </a>
                            </div>
                        </div>
                        <div className="newsletter__wrap">
                            <a href="https://gansub.com/s/OKrnusVYxQMLe5/" target="_blank" rel="noreferrer" className="newsletter__link">
                                    Signa upp för nyhetsbrev&nbsp;
                                    <BsArrowRight />
                            </a>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    )
}