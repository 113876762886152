import React, { Suspense, useState, useEffect } from 'react';

interface SuspenseWithDelayProps {
    children: React.ReactNode;
    fallback: React.ReactNode;
    delay?: number;
}

const SuspenseWithDelay: React.FC<SuspenseWithDelayProps> = ({ children, fallback, delay = 2000 }) => {
    const [isReadyToShowChildren, setIsReadyToShowChildren] = useState(false);

    useEffect(() => {
        let timeout = setTimeout(() => {
            setIsReadyToShowChildren(true);
        }, delay);

        return () => clearTimeout(timeout);
    }, [delay]);

    return (
        <Suspense fallback={fallback}>
            {isReadyToShowChildren ? children : fallback}
        </Suspense>
    );
}

export default SuspenseWithDelay;
