import '../assets/css/LoadingScreen.css';

export const LoadingScreen = () => {
    return (
        <div className="loading__screen fade-in">
            <video autoPlay loop muted playsInline>
                <source src={require('../assets/img/intro_vid.mp4')} type="video/mp4"/>
            </video>
        </div>
    );
}