import React, { lazy } from 'react';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { LoadingScreen } from './components/LoadingScreen';
import SuspenseWithDelay from './components/SuspenseWithDelay';
import { Contact } from './pages/Contact';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

const Home = lazy(() => import('./pages/Home'));
const About = lazy(() => import('./pages/About'));
const F12 = lazy(() => import('./pages/f12'));
const F12Studio = lazy(() => import('./pages/f12studio'));

function App() {
	return (
		<div className="App">
			<BrowserRouter>
				<SuspenseWithDelay fallback={<LoadingScreen />} delay={process.env.REACT_APP_SUSPENSE_DELAY ? parseInt(process.env.REACT_APP_SUSPENSE_DELAY) : 4800}>
					<Routes>
						<Route path="/" element={<Home />} />
						<Route path="/about" element={<About />} />
						<Route path="/f12" element={<F12 />} />
						<Route path="/f12studio" element={<F12Studio />} />
						<Route path="/contact" element={<Contact />} />
					</Routes>
				</SuspenseWithDelay>
			</BrowserRouter>
		</div>
	);
}

export default App;
