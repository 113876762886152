import '../assets/css/Contact.css';
import { Footer } from '../components/Footer';
import red_dresses from '../assets/img/About/red_dresses_large.jpg';
import { Link } from 'react-router-dom';
import { BsArrowLeft } from 'react-icons/bs';
import { Helmet } from 'react-helmet';

export const Contact = () => {
    return (
        <>
            <Helmet>
                <title>Kontakt | Fredsgatan 12</title>
                <meta name="description" content="Fredsgatan 12 är en mångsidig nöjesdestination som kombinerar en förstklassig restaurang med en pulserande nattklubb." />
                <link rel="canonical" href="https://fredsgatantolv.se/contact" />

                <meta property="og:title" content="Kontakt | Fredsgatan 12" />
                <meta property="og:description" content="Fredsgatan 12 är en mångsidig nöjesdestination som kombinerar en förstklassig restaurang med en pulserande nattklubb." />
                <meta property="og:url" content="https://fredsgatantolv.se/contact" />
                <meta property="og:image" content={red_dresses} />

                <meta property="twitter:title" content="Kontakt | Fredsgatan 12" />
                <meta property="twitter:description" content="Fredsgatan 12 är en mångsidig nöjesdestination som kombinerar en förstklassig restaurang med en pulserande nattklubb." />
                <meta property="twitter:image" content={red_dresses} />
            </Helmet>
            <div id="contact">
                <Link to="/" className="back__nav__link">
                        <BsArrowLeft />
                        Back
                    </Link>
                <div className="landing">
                    <div className="contact__info container">
                        <h1>Kontakt</h1>
                        <p className="hero__text">
                            Fredsgatan 12 är en mångsidig nöjesdestination som kombinerar en förstklassig restaurang med en pulserande nattklubb.
                            <br /><br />
                            Husets restaurang, La Boca, lockar gäster med en noga komponerad meny av läckra rätter från Sydamerika. Med fokus på högkvalitativa råvaror skapar kockarna, i ledning av Mattias Dahlström, kulinariska mästerverk.
                        </p>
                    </div>
                    <img src={red_dresses} alt="Red dresses" className="contact__image" />
                </div>
                {/* <form className="contact__form container">
                    <input type="text" placeholder="Namn" />
                    <input type="email" placeholder="E-post" />
                    <textarea placeholder="Meddelande" />
                    <button type="submit">Skicka</button>
                </form> */}
                <Footer />
            </div>
        </>
    );
}